html {
  /* font-size: 16px; */
  font-size: 100% !important;

  /* font-size: 18px; */
  @media (min-width: 1536px) {
    font-size: 112.5% !important;
  }
}

.smallFeedbackSwiper {
  .swiper-wrapper {
    align-items: center;
  }
}

button {
  &.btn-outline:hover,
  &.btn-active {
    background: rgba(0, 0, 0, 0.08);
  }
}
