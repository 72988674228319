/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 20px;
  height: 18px;
  right: 30px;
  top: 30px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #370110;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 26px;
  width: 26px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #370110;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  // background: #d3a56c;
  background: #fff;
  padding-top: 1rem;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #370110;
  padding-top: 1rem;
  font-weight: 600;
  font-size: 1.2rem;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 0.3rem 1.3rem;
}

.bm-item:hover {
  color: #fa3d7c;
  background: rgba(0, 0, 0, 0.05);
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
